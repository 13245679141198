import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {ApiPrefixInterceptor} from "./interceptors";
import {NgModule, Optional, SkipSelf} from "@angular/core";

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true},
    ],
    declarations: [
    ],
})
export class CoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule!');
        }
    }
}
