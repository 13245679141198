import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {UserService} from "../../../services/user";
import {AuthService} from "../../../services/auth";
import {UserModel} from "../../../services/user/models";
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from 'app/shared/shared.module';
import { ChangePasswordDialogComponent } from 'app/modules/auth/change-password-dialog/change-password-dialog.component';
import { SIGN_OUT_ROUTE } from 'app/shared/constants/auth';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, SharedModule],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: UserModel;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        public dialog: MatDialog,
        public translocoService: TranslocoService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this.user = this._userService.getCurrentUser();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Sign out
     */
    signOut(): void
    {
        this._authService.logOut().subscribe(() => this._router.navigate([SIGN_OUT_ROUTE]));
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
        });
      }

    changeLanguage(language: string): void {
      this.translocoService.setActiveLang(language);
      localStorage.setItem('currentLang', language);
    }
}
