import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "./app.reducer";

export const getAppState =
  createFeatureSelector<AppState>('application');

export const getFilterOptions = createSelector(
    getAppState,
  (state: AppState) => state && state.filterOptions
);
export const getFilteredResult = createSelector(
    getAppState,
  (state: AppState) => state && state.filterResult
);
export const getNavigations = createSelector(
  getAppState,
(state: AppState) => state && state.navigation
);