/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const project = {
    githubIssues      : {
        overview: {
            'this-week': {
                'in-come'   : 0,
                'sold-tickets': 0,
                'revenue'        : 0,
                'winning-tickets'     : 0,
                'prize-payment'    : 0
            },
            'last-week': {
                'in-come'   : 0,
                'sold-tickets': 0,
                'revenue'        : 0,
                'winning-tickets'     : 0,
                'prize-payment'    : 0
            },
        },
        labels  : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        series  : {
            'this-week': [
                {
                    name: 'Sold Tickets',
                    type: 'line',
                    data: [],
                },
                {
                    name: 'Winning Tickets',
                    type: 'column',
                    data: [],
                },
            ],
            'last-week': [
                {
                    name: 'Sold Tickets',
                    type: 'line',
                    data: [],
                },
                {
                    name: 'Winning Tickets',
                    type: 'column',
                    data: [],
                },
            ],
        },
    }
};
