import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Navigation} from 'app/core/navigation/navigation.types';
import {Observable, of, ReplaySubject, tap} from 'rxjs';
import {PermissionService} from "../../services/permission";

@Injectable({providedIn: 'root'})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _permissionService: PermissionService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    this.get();
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    const defaultNav = this._permissionService.buildNavigation().subscribe((data) => {
      this._navigation.next(
        {
          default: data
        }
      );
    });
    
    return this._navigation;
  }
}
