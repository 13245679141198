<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <mat-icon
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item
        [matMenuTriggerFor]="userSetting">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
        [matMenuTriggerFor]="languageSubMenu">
        <mat-icon [svgIcon]="'heroicons_outline:language'"></mat-icon>
        <span>Language</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userSetting="matMenu">
    <button
        mat-menu-item
        (click)="openDialog()">
        <span>Change Password</span>
    </button>
</mat-menu>

<mat-menu #languageSubMenu="matMenu">
  <button mat-menu-item (click)="changeLanguage('en')">English (EN)</button>
  <button mat-menu-item (click)="changeLanguage('vn')">Vietnamese (VI)</button>
</mat-menu>
