import { createReducer, on } from "@ngrx/store";
import { AppActions } from ".";
import { FuseNavigationItem } from "@fuse/components/navigation";

export interface AppState {
    filterOptions: any;
    filterResult: any;
    navigation: FuseNavigationItem[];
}

export const initialState: AppState = { 
    filterOptions: null,
    filterResult: null,
    navigation: []
};

const reducer = createReducer(
    initialState,
    on(AppActions.updateFilterOptions, (state, { filterOptions }) => ({ ...state, filterOptions: filterOptions })),
    on(AppActions.getListWithFilterSuccess, (state, { data }) => ({ ...state, filterResult: data })),
    on(AppActions.updateNavigation, (state, { nav }) => ({ ...state, navigation: nav }))
);

export function appReducer(state, action) {
    return reducer(state, action);
}