import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { FuseConfirmationService } from '../../../@fuse/services/confirmation';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'app/services/auth';
import { Actions, ofType } from '@ngrx/effects';
import { AppActions } from 'app/modules/store/app';
import { Store } from '@ngrx/store';
import { AppState } from 'app/modules/store/app/app.reducer';

/**
 * Intercept
 *
 * @param req
 * @param next
 */

let isRefreshing = false;

export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const fuseConfirmationService = inject(FuseConfirmationService);
  const transLocoService = inject(TranslocoService);
  const _actions$ = inject(Actions);
  const appState = inject(Store<AppState>);
  // Clone the request object
  let newReq = req.clone();

  if (_isTargetApiRoute(newReq)) {
    if (
      authService.accessToken &&
      AuthUtils.isTokenExpired(authService.accessToken)
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        if (authService.refreshToken && AuthUtils.isTokenExpired(authService.refreshToken)) {
          appState.dispatch(AppActions.logout());
          authService.signOut();
          location.reload();
        }else{
          appState.dispatch(AppActions.refreshToken())
        }

        return _actions$.pipe(
          ofType(AppActions.refreshTokenSuccess),
          switchMap(() => {
            isRefreshing = false;
            return sendHttpRequest(
              newReq,
              authService,
              transLocoService,
              fuseConfirmationService,
              next
            );
          }),
        );
      }
    }
    return sendHttpRequest(
      newReq,
      authService,
      transLocoService,
      fuseConfirmationService,
      next
    );
  }

  return next(newReq);
};

export const sendHttpRequest = (
  request,
  authService,
  transLocoService,
  fuseConfirmationService,
  next: HttpHandlerFn
) => {
  let newReq = request.clone();
  if (authService.accessToken && _isTargetApiRoute(newReq)) {
    newReq = request.clone({
      headers: request.headers.set(
        'Authorization',
        'Bearer ' + authService.accessToken
      ),
    });
  }

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      let message = error?.error?.message;
      const errCode = error?.error?.code;

      if (error instanceof HttpErrorResponse) {
        switch (true) {
          case error.status === 401: {
            authService.signOut();

            location.reload();
            break;
          }
          case error.status === 403: {
            message = transLocoService.translate('Common.NoPermission');
            break;
          }
          case error.status === 400: {
            message = transLocoService.translate(message || errCode);
            break;
          }
          case error.status === 500: {
            message = transLocoService.translate('Common.SomethingWentWrong');
            break;
          }
        }

        const confirmation = fuseConfirmationService.open({
          title: 'Error',
          message: message,
          icon: {
            color: 'warn',
          },
          actions: {
            confirm: {
              label: 'Ok',
              color: 'accent',
            },
            cancel: {
              show: false,
            },
          },
        });

        confirmation.afterClosed().subscribe((result) => {
          if (result == 'confirmed') {
            if (
              error.error.title ===
              'LOTTERY-BUY_LOTTERY_NOT_AVAILABLE_AT_THIS_TIME'
            ) {
              location.reload();
            }
          }
        });
      }

      return throwError(error);
    })
  );
};

export const _isTargetApiRoute = (req: HttpRequest<unknown>): boolean => {
  const url = req.url;
  return url.indexOf('assets/icon') < 0 && url.indexOf('assets/i18n') < 0;
};
