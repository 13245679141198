<div class="min-w-100">
    <form class="flex flex-col" [formGroup]="changePasswordForm">
        <mat-form-field class="w-100">
            <mat-label>Old Password</mat-label>
            <input
                matInput
                type="password"
                formControlName="oldPassword"
                autocomplete="off"
                placeholder="Old password"
                required
            />
            <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('required')">
                Old Password is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>New Password</mat-label>
            <input
                matInput
                type="password"
                formControlName="newPassword"
                autocomplete="off"
                placeholder="New password"
                required
            />
            <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
                New Password is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Confirm New Password</mat-label>
            <input
                matInput
                type="password"
                formControlName="confirmPassword"
                autocomplete="off"
                placeholder="Confirm new password"
                required
            />
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
                Confirm new Password is required
            </mat-error>
        </mat-form-field>

        <div class="flex justify-center items-center">
            <button
                mat-raised-button
                mat-dialog-close
                [mat-dialog-close]="false"
                cdkFocusInitial>
                Cancel
            </button>
            <button
                mat-raised-button
                (click)="changeMyPassword()"
                color="primary"
                class="ml-2">
                Confirm
            </button>
        </div>
    </form>
</div>
