import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import {PermissionService} from "../../../services/permission";

export const DefaultRouteGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
  const router: Router = inject(Router);

  return inject(PermissionService).canAccess(state.url).pipe(
    switchMap(((isValid: Boolean) =>{
      if(!isValid){
        const urlTree = router.parseUrl('404-not-found');

        return of(urlTree);
      }

      return of(true);
    }))
  );
};
