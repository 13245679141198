import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import {AuthService} from "../../../../services/auth";
import {UserService} from "../../../../services/user";
import {UserModel} from "../../../../services/user/models";
import {PermissionService} from "../../../../services/permission";
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/modules/store/app/app.reducer';
import { getNavigations } from 'app/modules/store/app/app.selectors';
import { AppActions } from 'app/modules/store/app';

@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [FuseLoadingBarComponent, FuseVerticalNavigationComponent, NotificationsComponent, UserComponent, NgIf, MatIconModule, MatButtonModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy
{
  isScreenSmall: boolean;
  navigation: FuseNavigationItem[];
  navigation$: Observable<FuseNavigationItem[]>;
  user: UserModel;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _authService: AuthService,
    private _userSerivce: UserService,
    private _permissionService: PermissionService,
    private _appState: Store<AppState>
  )
  {
    this._permissionService.getMyPermission().subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number
  {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this.getUserInformation();
    this._userSerivce.user$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((user: UserModel) =>
        {
          this.user = user;
        });
    
    this._appState.dispatch(AppActions.getMyPermission());
    this.navigation$ = this._appState.pipe(select(getNavigations));
    
    this.navigation$.subscribe((navigation: FuseNavigationItem[]) => {
      if(navigation) this.navigation = navigation;
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({matchingAliases}) =>
      {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void
  {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if ( navigation )
    {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  getUserInformation(){
    this._userSerivce.getUserInformation();
  }
}
