import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {DefaultRouteGuard} from "./core/auth/guards/default-route.guard";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'portal',
        children: [
          {
            path: 'dashboard',
            canActivate: [DefaultRouteGuard],
            loadChildren: () => import('app/modules/admin/dashboard/dashboard.route')
          },
          {
            path: 'role-management',
            canActivate: [DefaultRouteGuard],
            loadChildren: () => import('app/modules/admin/role-management/role-management.route')
          },
          {
            path: 'permission-management',
            canActivate: [DefaultRouteGuard],
            loadChildren: () => import('app/modules/admin/permission-management/permission-management.route')
          }
        ]

      },
      {
        path: 'lottery',
        children: [
          {path: 'lottery-cycle', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/lottery/lottery-cycle/lottery-cycle.route')},
          {path: 'giveaway-tickets', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/lottery/give-away-ticket/give-away-ticket.route')},
          {path: 'dashboard', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/lottery/dashboard/dashboard.routes')},
          {path: 'base-lottery-cycle-config', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/lottery/base-lottery-cycle-config/base-lottery-cycle-config.route')},
        ]
      },
      {
        path: 'sso',
        children: [
          // {path: 'dashboard', loadChildren: () => import('app/modules/user-service-admin/dashboard/dashboard.route')},
          {path: 'client-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/user-service-admin/client-management/client-management.route')},
          {path: 'user-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/user-service-admin/user-management/user-management.route')},
          {path: 'user-migration-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/user-service-admin/user-migration-management/user-migration-management.route')},
          {path: 'job-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/user-service-admin/job-management/job-management.route')},
          {path: 'kyc-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/user-service-admin/kyc-management/kyc-management.route')},
        ]
      },
      {
        path: 'eventticket',
        children: [
          {path: 'event-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/event-ticket/event-management/event-management.route')},
          {path: 'event-manipulation', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/event-ticket/event-manipulation/event-manipulation.route')},
          {path: 'booking-management', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/event-ticket/booking-management/booking-mangement.route')},
          {path: 'ticket-behavior', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/event-ticket/ticket-behavior/ticket-behavior.route')},
          {path: 'checkin-ticket', canActivate: [DefaultRouteGuard], loadChildren: () => import('app/modules/event-ticket/checkin-ticket/checkin-ticket.route')},
        ]
      },
      {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/error404/error404.route')}
    ]
  },
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    data: {
      layout: 'empty'
    },
    component: LayoutComponent,
    children: [
      {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
      {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')}
    ]
  }
];
