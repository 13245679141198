import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from 'app/services/user';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
  });

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>, private _userService: UserService, private _fuseConfirmationService: FuseConfirmationService) {}

  changeMyPassword() {
    if (this.changePasswordForm.valid) {
      const { oldPassword, newPassword, confirmPassword } = this.changePasswordForm.value;
      if (newPassword !== confirmPassword) {
        this.changePasswordForm.controls.confirmPassword.setErrors({ notMatch: true });
        return;
      } 
        const confirmation = this._fuseConfirmationService.open({
          title  : 'Error',
          message: "Are you sure you want to Update this information? This action cannot be undone!",
          icon: {
              color: 'warn'
          },
          actions: {
              confirm: {
                  label: 'Ok',
                  color: 'accent'
              },
              cancel: {
                  show: false,
              }
          },
      });

      confirmation.afterClosed().subscribe((result) => {
          if(result == 'confirmed'){
            this._userService.changeMyPassword(oldPassword, newPassword).subscribe((res) => {
              const confirm = this._fuseConfirmationService.open({
                title  : 'Success',
                message: "Change Password Successfully!!",
                icon: {
                    color: 'success'
                },
                actions: {
                    confirm: {
                        label: 'Ok',
                        color: 'accent'
                    },
                    cancel: {
                        show: false,
                    }
                },
              });
              
              confirm.afterClosed().subscribe((result) => {
                this.dialogRef.close();
              });
              
            });
          }
      });  
    }
  }
}
