import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/services/auth';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
  // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is not authenticated...
            if ( !authenticated )
            {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`/auth/sign-in?${redirectURL}`);
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};

export const AuthSystemAdminGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
  const router: Router = inject(Router);

  // Check the authentication status
  return inject(AuthService).checkSystemAdmin().pipe(
    switchMap((isAdmin) =>
    {
      // If the user is not authenticated...
      if (state.url == `/base-config`)
      {
        if(!isAdmin){
          const urlTree = router.parseUrl(`/`);
          return of(urlTree);
        }else {
          return of(true);
        }
      }
      return of(true);
      // Allow the access

    }),
  );
};
